<template>
  <section class="d-flex flex-column h-100">
    <!-- <AppHeader /> -->
    <AppContent />
    <!-- <AppFooter /> -->
  </section>
</template>

<script>
// import AppHeader from "@/components/AppHeader.vue";
import AppContent from "@/components/AppContent.vue";
// import AppFooter from "@/components/AppFooter.vue";
export default {
  name: 'AppBody',
  components: {
    // AppHeader,
    AppContent,
    // AppFooter
  },
}
</script>

