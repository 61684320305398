<template>
  <AppBody />
</template>

<script>
import AppBody from '@/components/AppBody.vue'

export default {
  name: 'Home',
  components: {
    AppBody
  }
}
</script>
